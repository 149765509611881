
import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import CloudNativeKick from "../sections/products/CloudNativeKick"
import FAQ from "../sections/landing3/FAQ"
import Pricing from "../sections/pricing/PricingTable2"
import VideoCTA from "../sections/landing3/VideoCTA"
import Content1 from "../sections/landing6/Content1"
import Content2 from "../sections/landing6/Content2"
import Content3 from "../sections/landing6/Content3"
import Offer from "../sections/landing6/Offer"
import Reviews from "../sections/landing6/Reviews"
import Testimonial from "../sections/landing6/Testimonial"
import CaseStudies from "../sections/landing7/CaseStudies"
import Fact from "../sections/landing7/Fact"

const Collection = () => {
  return (
    <>
      <PageWrapper>
        <Hero title="Product Offerings"></Hero>
        <CloudNativeKick />
        <FAQ />
        <Pricing />
        <VideoCTA />
        <Content1 />
        <Content2 />
        <Content3 />
        <Offer />
        <Reviews />
        <Testimonial />
        <CaseStudies />
        <Fact />
      </PageWrapper>
    </>
  );
};
export default Collection;
